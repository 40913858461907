import React, { ReactNode, useContext } from 'react';
import { AuthContext, IAuthContext, AuthProvider as LibAuthProvider } from 'react-oauth2-code-pkce';
import LocalStorage from '../services/localStorage';

const authRetriesKey = 'AUTH_RETRIES';
export const lastSuccessfulRetryKey = 'AUTH_LAST_SUCCESSFUL_RETRY';
const maxAuthRetries = 3;
const searchStringKey = 'AUTH_RESTORE_SEARCH_STRING';

export function useAuth(): IAuthContext {
  return useContext<IAuthContext>(AuthContext);
}

export function AuthProvider({ children }: { children: ReactNode }): ReactNode {
  if (LocalStorage.isAvailable()) {
    return (
      <LibAuthProvider
        authConfig={{
          clientId: process.env.GATSBY_AUTH_CLIENT_ID!,
          scope: process.env.GATSBY_AUTH_SCOPE!,
          authorizationEndpoint: process.env.GATSBY_AUTH_AUTH_URI!,
          redirectUri: process.env.GATSBY_AUTH_REDIRECT_URI!,
          tokenEndpoint: process.env.GATSBY_AUTH_TOKEN_URI!,
          decodeToken: true,
          onRefreshTokenExpire: () => {},
          autoLogin: true,
          preLogin: () => {
            LocalStorage.set(searchStringKey, location.search);
          },
          postLogin: () => {
            location.search = LocalStorage.get<string>(searchStringKey) || '';
            LocalStorage.remove(searchStringKey);
          },
        }}
      >
        <AuthenticatedContent>{children}</AuthenticatedContent>
      </LibAuthProvider>
    );
  } else {
    return null;
  }
}

export function AuthenticatedContent({ children }: { children: ReactNode }): ReactNode {
  // On Firefox the first login attempt sometimes fails (probably because of trying before the page is completely loaded, in connection with an expired token refresh).
  // Therefore, we attempt the login multiple times.
  const { token, login, loginInProgress } = useAuth();
  const authRetries = LocalStorage.get<number>(authRetriesKey) || 0;

  if (token) {
    LocalStorage.set(authRetriesKey, 0);
    LocalStorage.set(lastSuccessfulRetryKey, Date.now());
    return children;
  } else if (!loginInProgress && authRetries < maxAuthRetries) {
    LocalStorage.set(authRetriesKey, authRetries + 1);
    login();
    return null;
  } else {
    return null;
  }
}
