import useSWR from 'swr';
import { useTranslation } from '@dssf/component-library';
import { useAuth } from '../context/auth';
import { getIndexed } from '../services/getIndexed';
import { useSpaceConfig, useSpaceId } from '../context/spaceConfig';
import { SpaceProvider } from '../types/spaceConfig';
import { Indexed } from '../types/indexed';
import { AppFlavourService } from '../services/appFlavourService';
import { SpaceAdministrationService } from '../services/spaceAdministrationService';
import { useMemo } from 'react';
import { LLMModelData, useLLMModelData } from './llmModelData';
import { SchemaSupportedSpaceDto } from '@askbosch/askbosch-fm-space-administration-openapi-npm';
import {
  SchemaBackendConfigurationDto,
  SchemaSupportedModelDto,
  SchemaSupportedSpacesDto,
  SchemaUserSpaceAuthorizationsDto,
} from '@askbosch/askbosch-fm-space-administration-openapi-npm/dist/generated/space-administration-api';

export const fallbackLLMId = 'GPT4O_MINI';
export const fallbackSpaceId = 'PUBLIC';
export const fallbackSpaceProvider = 'PUBLIC';
export const defaultFavouriteSpaces: String[] = [];
export const PUBLIC_SPACE = 'public';
const llmOrder = ['GPT3', 'PALM2', 'GPT4', 'GEMINI'].reverse(); // order of LLMs, order between basic and advanced LLMs does not matter

export interface UseSpacesReturn {
  spaces: SchemaSupportedSpaceDto[];
  indexedSpaces: Indexed<SchemaSupportedSpaceDto>;
  currentSpace: SchemaSupportedSpaceDto | null;
  indexedLLMs: Indexed<Indexed<SchemaSupportedModelDto>>;
  availableLLMs: {
    BASIC: (LLMModelData & SchemaSupportedModelDto)[];
    ADVANCED: (LLMModelData & SchemaSupportedModelDto)[];
  };
  currentSpaceIndexedLLMs: Indexed<SchemaSupportedModelDto> | null;
  reloadSpaces: (provider?: SpaceProvider) => Promise<SchemaSupportedSpacesDto | undefined>;
  isLoading: boolean;
  advancedLLMs: SchemaSupportedModelDto[];
  advancedEnabled: boolean;
  missingProviders: string[];
  backendConfiguration: SchemaBackendConfigurationDto;
  userAuthorizations: SchemaUserSpaceAuthorizationsDto;
}

export interface UseSpaceLLMsReturn {
  currentLLM: SchemaSupportedModelDto | null;
}

export function useSpaces(): UseSpacesReturn {
  const { lang } = useTranslation();
  const { token } = useAuth();
  const { currentSpaceId, handleSpaceUpdate } = useSpaceId();
  const { llmModelData } = useLLMModelData();

  const getSpaces = async (provider?: SpaceProvider) => {
    const result = await SpaceAdministrationService.getSpaces(token, provider);
    handleSpaceUpdate(result);
    return result;
  };

  // due to using hooks doing this inside a loop is not really possible, but number of providers is limited
  const {
    data: publicData,
    isLoading: publicLoading,
    mutate: publicMutate,
  } = useSWR<SchemaSupportedSpacesDto>(`PUBLIC-${lang}`, () => getSpaces('PUBLIC'), { revalidateIfStale: false });
  const {
    data: brainData,
    isLoading: brainLoading,
    mutate: brainMutate,
  } = useSWR<SchemaSupportedSpacesDto>(AppFlavourService.isBeta && `BRAIN-${lang}`, () => getSpaces('BRAIN'), {
    revalidateIfStale: false,
  });
  const {
    data: ragData,
    isLoading: ragLoading,
    mutate: ragMutate,
  } = useSWR<SchemaSupportedSpacesDto>(AppFlavourService.isBeta && `RAG-${lang}`, () => getSpaces('RAG'), {
    revalidateIfStale: false,
  });
  const {
    data: skynetData,
    isLoading: skynetLoading,
    mutate: skynetMutate,
  } = useSWR<SchemaSupportedSpacesDto>(AppFlavourService.isBeta && `SKYNET-${lang}`, () => getSpaces('SKYNET'), {
    revalidateIfStale: false,
  });

  const aggregateSupportedSpacesResults = (
    results: (SchemaSupportedSpacesDto | undefined)[] | undefined,
  ): SchemaSupportedSpacesDto => {
    return {
      spaces: (results ?? []).reduce(
        (acc, e) => (e ? [...acc, ...(e?.spaces ?? [])] : acc),
        [] as SchemaSupportedSpaceDto[],
      ),
      missingProviders: Array.from(new Set((results ?? []).flatMap(result => result?.missingProviders ?? []))),
      backendConfiguration: {
        ...(publicData?.backendConfiguration ?? {}),
        ...(brainData?.backendConfiguration ?? {}),
        ...(ragData?.backendConfiguration ?? {}),
        ...(skynetData?.backendConfiguration ?? {}),
      } as SchemaBackendConfigurationDto,
      userAuthorizations: {
        authorizations: {
          ...(publicData?.userAuthorizations?.authorizations ?? {}),
          ...(brainData?.userAuthorizations?.authorizations ?? {}),
          ...(ragData?.userAuthorizations?.authorizations ?? {}),
          ...(skynetData?.userAuthorizations?.authorizations ?? {}),
        },
      },
    };
  };

  const { missingProviders, userAuthorizations, spaces, backendConfiguration } = useMemo(
    () => aggregateSupportedSpacesResults([publicData, brainData, ragData, skynetData]),
    [publicData, brainData, ragData, skynetData],
  );

  const indexedSpaces = useMemo(() => getIndexed(spaces ?? []), [spaces]);
  const indexedLLMs =
    (indexedSpaces &&
      Object.fromEntries(
        Object.entries(indexedSpaces).map(([id, space]) => [id, getIndexed(space.config?.llms ?? [])]),
      )) ??
    null;

  const reloadSpaces = async (provider?: SpaceProvider): Promise<SchemaSupportedSpacesDto | undefined> => {
    switch (provider) {
      case 'PUBLIC':
        return publicMutate();
      case 'BRAIN':
        return brainMutate();
      case 'RAG':
        return ragMutate();
      case 'SKYNET':
        return skynetMutate();
      default:
        return aggregateSupportedSpacesResults(
          await Promise.all([publicMutate(), brainMutate(), ragMutate(), skynetMutate()]),
        );
    }
  };

  const currentSpace = (currentSpaceId && indexedSpaces[currentSpaceId]) || null;

  const availableLLMs: {
    BASIC: (LLMModelData & SchemaSupportedModelDto)[];
    ADVANCED: (LLMModelData & SchemaSupportedModelDto)[];
  } = currentSpace?.config?.llms?.reduce(
    (s, e) => {
      if (llmModelData[e.id]) {
        s[(e.subscription?.name as 'ADVANCED') ?? 'BASIC'].push({
          ...llmModelData[e.id],
          ...e,
        });
        s[(e.subscription?.name as 'ADVANCED') ?? 'BASIC'].sort(
          (a, b) => llmOrder.indexOf(b.id) - llmOrder.indexOf(a.id),
        );
      }
      return s;
    },
    { BASIC: [], ADVANCED: [] } as {
      BASIC: (LLMModelData & SchemaSupportedModelDto)[];
      ADVANCED: (LLMModelData & SchemaSupportedModelDto)[];
    },
  ) ?? { BASIC: [], ADVANCED: [] };

  const advancedLLMs = currentSpace?.config?.llms?.filter(model => model.subscription?.name === 'ADVANCED') ?? [];
  const advancedEnabled = advancedLLMs?.some(model => model.enabled) ?? false;

  return {
    spaces: spaces ?? [],
    indexedSpaces,
    currentSpace,
    indexedLLMs,
    availableLLMs,
    currentSpaceIndexedLLMs: (currentSpaceId && indexedLLMs[currentSpaceId]) || null,
    isLoading: publicLoading || brainLoading || ragLoading || skynetLoading,
    advancedLLMs: advancedLLMs,
    advancedEnabled: advancedEnabled,
    reloadSpaces,
    missingProviders: missingProviders ?? [],
    backendConfiguration,
    userAuthorizations: userAuthorizations ?? { authorizations: {} },
  };
}

export function useSpaceLLMs(): UseSpaceLLMsReturn {
  const { currentSpaceId, currentLLMId } = useSpaceConfig();
  const { indexedLLMs } = useSpaces();

  return {
    currentLLM: (currentLLMId && indexedLLMs?.[currentSpaceId ?? '']?.[currentLLMId]) || null,
  };
}
